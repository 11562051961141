import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
declare var $: any;
declare var swal: any;
declare const navigator: any; 

@Component({
  selector: 'app-newdashboard',
  templateUrl: './newdashboard.component.html',
  styleUrls: ['./newdashboard.component.css']
})
export class NewdashboardComponent implements OnInit {

  public clicked: boolean = false;
  public clicked1: boolean = false;
  public clicked2: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private _phpService: phpservice, public datepipe: DatePipe) {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
      $('.ui.label.fluid.dropdown').dropdown({
        useLabels: false
      });
    }, 1000);
  }
  private isCountdownInitialized: boolean;

  filterLoader: boolean = true;

  Executivename = "";
  rmpaneldata = false;
  rmdata = false;
  // cspaneldata = false;
  // csdata = false;
  assignedleadscounts: any;
  fromdate: any;
  todate: any;
  rmid: any;
  execid: any;
  propertyid: any;
  propertyname: any;
  team: any;
  // csid: any;
  activeleadscount: any;
  tookactionmenu = false;
  activemenu = false;
  followupmenu = false;
  assignedleadstable = true;
  tookactiontable = false;
  closedtable = false;
  followuptable = false;
  followupcounts: any;
  stagesections: any;
  tookaction: any;
  closedleads: any;
  followups: any;
  followupsectionid: any;
  static count: number;
  static activecount: number;
  static closedcount: number;
  static followupscount: number;
  static reassignedcount: number;
  callerleads: any;
  callerleadscounts: any;
  telecallers: any;
  leads: any;
  executives: any;
  actionid: any;
  loadmorecountactivecounts: any;
  loadmorecountfollowups: any;
  loadmoreclosedcounts: any;

  totalcounts: any;
  totalusvcounts: any;
  totalrsvcounts: any;
  junkusvcounts: any;
  junkrsvcounts: any;
  allcountsloadmore: any;
  tookactioncounts: any;
  pendingcounts: any;
  activecount: any;
  inactivecount: any;
  f2fcount: any;
  usvcount: any;
  svcount: any;
  rsvcount: any;
  negocount: any;
  closedcount: any;
  leadreassigning: any;
  // csleadassign = false;
  rmleadassign = false;
  inactivetable = false;
  junkleads: any;
  onlyrm = false;
  onlycs = false;
  rmonly: any;
  csonly: any;
  followupsections:any;
  filterdata = false;
  executivefilterview = false;
  propertyfilterview = false;
  stagefilterview = false;
  stagestatusfilterview = false;
  datefilterview = false;
  execname: any;
  fupsectionname: any;
  selecteduser: any;
  currentDate = new Date();
  todaysdate: any;
  enchanting = false;
  grsitara = false;
  todaystotalcounts: any;
  todayscheduled: any;
  scheduledtoday: any;
  todayscheduledusv: any;
  todayscheduledrsv: any;
  todayscheduledfn: any;
  overduescounts: any;
  overdueslists: any;
  upcomingvisits: any;
  upcomingfollowups: any;
  overdue: any;
  notinterested: any;
  interested: any;
  inprogress: any;
  booked: any;
  bookingrequest: any;
  rejected: any;
  retailmoved: any;
  junk = true;
  propertyparam: any;
  todaysscheduledparam: any;

  todaysvisitedparam: any;
  yesterdaysvisitedparam: any;
  allvisitparam: any;

  upcomingvisitparam: any;
  upcomingfollowupparam: any;
  overdueparam: any;
  junkparam: any;
  bookedparam: any;
  bookingreqparam:any;
  rejectreqparam:any;
  retailmovedparam: any;
  
  inprogressparam: any;
  interestedparam: any;
  currentdateforcompare = new Date();
  todaysdateforcompare: any;
  yesterdaysdateforcompare: any;
  tomorrowsdateforcompare: any;
  activitypage: Boolean;
  allvisittracks: Boolean;
  filterview: Boolean;
  stagevalue: any;
  stagestatusval: any;
  stagestatusvaltext: any;
  stagestatus = false;
  retailmovelink = false;
  username: any;
  datecustomfetch: any;
  directteamfound: Boolean;
  onlyadmin: Boolean;
  adminandexec: Boolean;

  withoutQueryParams$: Observable<boolean>;

  ngOnInit() {
    this.roleid = localStorage.getItem('Role');
    this.userid = localStorage.getItem('UserId');
    this.username = localStorage.getItem('Name');
    if(this.roleid == '1'){
      this.onlyadmin = true;
    }

    if(this.userid == '40007'){
     this.adminandexec = true;
    }else if(this.userid == '40078'){
     this.adminandexec = true;
    }else if(this.userid == '40045'){
     this.adminandexec = true;
    }else if(this.userid == '40050'){
      this.adminandexec = true;
     }else if(this.userid == '40043'){
      this.adminandexec = true;
     }else if(this.userid == '40041'){
      this.adminandexec = true;
     }else if(this.userid == '1'){
      this.adminandexec = true;
    }else{
     this.adminandexec = false;
    }
    
    // *********************load the required template files*********************
    let node1: any = document.createElement('link');
    node1.setAttribute('href','https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900|Roboto+Slab:400,700');
    node1.rel = 'stylesheet';
    node1.type = 'text/css';
    node1.id = "dashboard_dynamic_links_1";
    document.getElementsByTagName('head')[0].appendChild(node1);

    let node2: any = document.createElement('link');
    node2.setAttribute('href','https://fonts.googleapis.com/icon?family=Material+Icons+Round');
    node2.rel = 'stylesheet';
    node2.type = 'text/css';
    node2.id = "dashboard_dynamic_links_2";
    document.getElementsByTagName('head')[0].appendChild(node2);

    let node3: any = document.createElement('link');
    node3.setAttribute('href','https://crmsales.right2shout.in/assets/css/material-dashboard.css?v=3.0.0');
    node3.rel = 'stylesheet';
    node3.type = 'text/css';
    node3.id = "dashboard_dynamic_links_3";
    document.getElementsByTagName('head')[0].appendChild(node3);

    // let node4: any = document.createElement('script');
    // node4.src = 'https://crmsales.right2shout.in/assets/js/core/bootstrap.min.js';
    // node4.type = 'text/javascript';
    // node4.async = true;
    // node4.charset = 'utf-8';
    // node4.id = "dashboard_dynamic_links_4";
    // document.getElementsByTagName('head')[0].appendChild(node4);

    let node5: any = document.createElement('script');
    node5.src = 'https://cdn.jsdelivr.net/momentjs/latest/moment.min.js';
    node5.type = 'text/javascript';
    node5.async = true;
    node5.charset = 'utf-8';
    node5.id = "dashboard_dynamic_links_5";
    document.getElementsByTagName('head')[0].appendChild(node5);

    let node6: any = document.createElement('script');
    node6.src = 'https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js';
    node6.type = 'text/javascript';
    node6.charset = 'utf-8';
    node6.id = "dashboard_dynamic_links_6";
    document.getElementsByTagName('head')[0].appendChild(node6);

    let node7: any = document.createElement('link');
    node7.setAttribute('href','https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css');
    node7.rel = 'stylesheet';
    node7.type = 'text/css';
    node7.id = "dashboard_dynamic_links_7";
    document.getElementsByTagName('head')[0].appendChild(node7);

    // *********************load the required template files*********************
    this.mandateprojectsfetch();
    this.getleadsdata();

    var curmonth = this.currentdateforcompare.getMonth()+1;
    var curmonthwithzero = curmonth.toString().padStart(2, "0");
    // Todays Date
    var curday = this.currentdateforcompare.getDate();
    var curdaywithzero = curday.toString().padStart(2, "0");
    this.todaysdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;
    // Todays Date
    // Yesterdays Date
    var yesterday = this.currentdateforcompare.getDate()-1;
    var yesterdaywithzero = yesterday.toString().padStart(2, "0");
    this.yesterdaysdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + yesterdaywithzero;
    // Yesterdays Date
    // Tomorrows Date
    var tomorrow = this.currentdateforcompare.getDate()+1;
    var tomorrowwithzero = tomorrow.toString().padStart(2, "0");
    this.tomorrowsdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + tomorrowwithzero;
    // Tomorrows Date
    
    
    if (localStorage.getItem('Role') == null) {
      this.router.navigateByUrl('/login');
    }
    if (localStorage.getItem('Role') == '1') {
      this.tookactiontable = true;
      this.assignedleadstable = false;
    }else if(localStorage.getItem('Role') == '50002'){

      this.rmid = localStorage.getItem('UserId');
      
    } else {
      this.tookactiontable = false;
      this.assignedleadstable = true;
    }
    // this._phpService
    //   .getfollowupsections()
    //   .subscribe(followupsection => {
    //     this.followupsections = followupsection;
    //   });
    const elements = document.getElementsByClassName("modalclick");
    while (elements.length > 0) elements[0].remove();
    const el = document.createElement('div');
    el.classList.add('modalclick');
    document.body.appendChild(el);
    NewdashboardComponent.count = 0;
    NewdashboardComponent.activecount = 0;
    NewdashboardComponent.followupscount = 0;
    NewdashboardComponent.reassignedcount = 0;
    NewdashboardComponent.closedcount = 0;

    this.withoutQueryParams$ = this.route.queryParams.pipe(
      map(params => Object.keys(params).length === 0)
  );
  
  this.scripts();
  // $('.daterangepicker .applyBtn').attr('click', 'triggerclick()');
  }

  ngOnDestroy(){
    let element1 = document.getElementById('dashboard_dynamic_links_1');
    let element2 = document.getElementById('dashboard_dynamic_links_2');
    let element3 = document.getElementById('dashboard_dynamic_links_3');
    // let element4 = document.getElementById('dashboard_dynamic_links_4');
    let element5 = document.getElementById('dashboard_dynamic_links_5');
    let element6 = document.getElementById('dashboard_dynamic_links_6');
    element1.parentNode.removeChild(element1);
    element2.parentNode.removeChild(element2);
    element3.parentNode.removeChild(element3);
    // element4.parentNode.removeChild(element4);
    element5.parentNode.removeChild(element5);
    element6.parentNode.removeChild(element6);
  }
  mandateprojects: any;
  mandateprojectsfetch(){
    this._phpService.getmandateprojects().subscribe(mandates => { 
      if(mandates['status'] == 'True'){
        this.mandateprojects = mandates['mandateprojects'];
      }else{
        
      }
    });
  }

  calculateDiff(sentDate) {
    var date1:any = new Date(sentDate);
    var date2:any = new Date();
    var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      $('.modalclick').click(function(){
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
      });
    }
  }

  close()
  {
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
  }

  getleadsdata() {
    
    if(localStorage.getItem('Role') == '50002'){
      this.rmid = localStorage.getItem('UserId');
    }
    this.filterLoader = true;
    NewdashboardComponent.count = 0;
    this.actionid = "";
    this.route.queryParams.subscribe((paramss) => {
      // Updated Using Strategy
      this.todaysvisitedparam = paramss['todayvisited'];
      this.yesterdaysvisitedparam = paramss['yesterdayvisited'];
      this.allvisitparam = paramss['allvisits'];
      this.propertyid = paramss['property'];
      this.propertyname = paramss['propname'];
      this.execid = paramss['execid'];
      this.execname = paramss['execname'];
      this.stagevalue = paramss['stage'];
      this.fromdate = paramss['from'];
      this.todate = paramss['to'];
      this.stagestatusval = paramss['stagestatus'];
      this.team = paramss['team'];

      if(this.propertyid){
        this.propertyfilterview = true;

        this._phpService.checkdirectteamexist(this.propertyid).subscribe(executives => { 
          if(executives['status'] == 'True'){
            if(this.roleid == '1'){
              this.directteamfound = true;
            }else{
              this.directteamfound = false;
            }
            // alert("Data Found");
          }else{
            if(this.roleid == '1'){
              this.directteamfound = false;
            }else{
              this.directteamfound = false;
            }
            // alert("No Data Found");
          }
        });

        this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
          if(executives['status'] == 'True'){
            this.mandateexecutives = executives['mandateexecutives'];
          }else{
          }
        });

       }else{
        this.propertyfilterview = false;
        // ********************static method Need to change********************
        if(this.userid == '40007'){
          this.propertyid = '34779';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40078'){
          this.propertyid = '1830';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
              this.mandateexecutives = this.mandateexecutives.filter((exec)=>!(exec.id == 40078));
            }else{
            }
          });
        }else if(this.userid == '40045'){
          this.propertyid = '16793';
          this.team = '1';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40050'){
          this.propertyid = '1830';
          this.team = '1';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40041'){
          this.propertyid = '16793';
          this.team = '2';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }
        // else if(this.userid == '40073'){
        //   this.propertyid = '16793';
        //   this.team = '3';
        //   this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
        //     if(executives['status'] == 'True'){
        //       this.mandateexecutives = executives['mandateexecutives'];
        //     }else{
        //     }
        //   });
        // }
        // ********************static method Need to change********************
      }

      if(this.execid){
        this.executivefilterview = true;
        if(localStorage.getItem('Role') == '1'){
          this.rmid = this.execid;
        }else{
          // this.rmid = localStorage.getItem('UserId');
          this.rmid = this.execid;
        }
      }else{
        this.executivefilterview = false;
        if(localStorage.getItem('Role') == '1'){
          this.rmid = "";
        }else{
          this.rmid = localStorage.getItem('UserId');
        }
      }

      if(this.stagevalue){
        this.stagefilterview = true;
        if(this.stagevalue == "USV"){
          this.stagestatus = false;
          this.stagestatusval = "";
        }else{
          this.stagestatus = true;
          $("#option-4").prop( "checked", false );
          $("#option-5").prop( "checked", false );
          $("#option-6").prop( "checked", false );
        }
      }else{
        this.stagefilterview = false;
      }

      if(this.fromdate || this.todate){
        this.datefilterview = true;
        $("#fromdate").val(this.fromdate);
        $("#todate").val(this.todate);
      }else{
        this.datefilterview = false;
      }

      if(this.stagestatusval){
        this.stagestatusfilterview = true;
        if(this.stagestatusval == '1'){
          this.stagestatusvaltext = "Fixed";
        }else if(this.stagestatusval == '2'){
          this.stagestatusvaltext = "Refixed";
        }else if(this.stagestatusval == '3'){
          this.stagestatusvaltext = "Done";
        }
      }else{
        this.stagestatusfilterview = false;
      }


      if(this.todaysvisitedparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        this.clicked = false;
        this.clicked1 = true;
        this.clicked2 = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        var curmonth = this.currentDate.getMonth()+1;
        var curmonthwithzero = curmonth.toString().padStart(2, "0");
        var curday = this.currentDate.getDate();
        var curdaywithzero = curday.toString().padStart(2, "0");
        this.todaysdate = this.currentDate.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;
        
        this.fromdate = this.todaysdate;
        this.todate = this.todaysdate;
        this.batch2trigger();
      }else if(this.yesterdaysvisitedparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        this.clicked = false;
        this.clicked1 = false;
        this.clicked2 = true;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        var curmonth = this.currentDate.getMonth()+1;
        var curmonthwithzero = curmonth.toString().padStart(2, "0");
        var yesterday = this.currentdateforcompare.getDate()-1;
        var yesterdaywithzero = yesterday.toString().padStart(2, "0");
        this.yesterdaysdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + yesterdaywithzero;
        
        this.fromdate = this.yesterdaysdateforcompare;
        this.todate = this.yesterdaysdateforcompare;
        this.batch2trigger();
      }
      else if(this.allvisitparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.clicked = true;
        this.clicked1 = false;
        this.clicked2 = false;
      }
      // Updated Using Strategy
    });
  }

  // Updated Using Strategy

  apitrigger(){
      if(this.todaysscheduledparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        // this.batch1trigger();
      }else if(this.todaysvisitedparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        // this.batch1trigger();
      }else if(this.upcomingvisitparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        // this.batch1trigger();
      }else if(this.upcomingfollowupparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        // this.batch1trigger();
      }else if(this.overdueparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        // this.batch1trigger();
      }else if(this.allvisitparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
      }else if(this.inprogressparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
      }else if(this.interestedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
      }else if(this.bookedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
      }
  }


  batch2trigger(){
    // if($('#fromdate').val() == undefined){
    //   this.fromdate = "";
    // }else if($('#fromdate').val() == ""){
    //   this.fromdate = "";
    // }else{
    //   this.fromdate = $('#fromdate').val();
    // }
    // if($('#todate').val() == undefined){
    //   this.todate = "";
    // }else if($('#todate').val() == ""){
    //   this.todate = "";
    // }else{
    //   this.todate = $('#todate').val();
    // }
    if(this.fromdate == "" && this.todate == "" || this.fromdate == undefined && this.todate == undefined){
      this.datecustomfetch = "Custom";
    }else{
      this.datecustomfetch = this.fromdate+' - '+this.todate;
    }
    // All Visits Counts Fetch
    var paramcount = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcount).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.totalcounts = compleads['RMLeads'].length;
        this.allcountsloadmore = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.totalcounts = "0";
        this.allcountsloadmore = compleads['RMLeads'].length;
      }
    });
    // All Visits Counts Fetch

    // USV Counts Fetch
    var paramcountusv = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: "USV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcountusv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.totalusvcounts = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.totalusvcounts = "0";
      }
    });
    // USV Counts Fetch

    // JUNK USV Counts Fetch
    var paramcountusv = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'junk',
      stage: "USV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcountusv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.junkusvcounts = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.junkusvcounts = "0";
      }
    });
    // JUNK USV Counts Fetch

    // JUNK RSV Counts Fetch
    var paramcountusv = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'junk',
      stage: "RSV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcountusv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.junkrsvcounts = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.junkrsvcounts = "0";
      }
    });
    // JUNK RSV Counts Fetch

    // RSV Counts Fetch
    var paramcountusv = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: "RSV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcountusv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.totalrsvcounts = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.totalrsvcounts = "0";
      }
    });
    // RSV Counts Fetch
    

     // booked Counts Fetch
     var bookedvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Booked',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(bookedvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.booked = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.booked = "0";
      }
    });
    // booked Counts Fetch

    // Todays Scheduled Counts Fetch
    var todayscheduled = {
      datefrom: 'scheduledtoday',
      dateto: 'scheduledtoday',
      statuss: '',
      stage: this.stagevalue,
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayscheduled).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.todayscheduled = compleads['RMLeads'].length;
        this.scheduledtoday = compleads['RMLeads'];
      }else{
        this.filterLoader = false;
        this.todayscheduled = "0";
        this.scheduledtoday = [""];
      }
    });
    // Todays Scheduled Counts Fetch

    // Todays Scheduled USV Counts Fetch
    var todayscheduledusv = {
      datefrom: 'scheduledtoday',
      dateto: 'scheduledtoday',
      statuss: '',
      stage: "USV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayscheduledusv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.todayscheduledusv = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.todayscheduledusv = "0";
      }
    });
    // Todays Scheduled USV Counts Fetch

     // Todays Scheduled RSV Counts Fetch
     var todayscheduledrsv = {
      datefrom: 'scheduledtoday',
      dateto: 'scheduledtoday',
      statuss: '',
      stage: "RSV",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayscheduledrsv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.todayscheduledrsv = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.todayscheduledrsv = "0";
      }
    });
    // Todays Scheduled RSV Counts Fetch

    // Todays Scheduled Final Negotiation Counts Fetch
    var todayscheduledrsv = {
      datefrom: 'scheduledtoday',
      dateto: 'scheduledtoday',
      statuss: '',
      stage: "Final Negotiation",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayscheduledrsv).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.todayscheduledfn = compleads['RMLeads'].length;
      }else{
        this.filterLoader = false;
        this.todayscheduledfn = "0";
      }
    });
    // Todays Scheduled Final Negotiation Counts Fetch

    // Total Overdues Counts Fetch
    var overdues = {
      datefrom: 'overdue',
      dateto: 'overdue',
      statuss: '',
      stage: "",
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(overdues).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.filterLoader = false;
        this.overduescounts = compleads['RMLeads'].length;
        this.overdueslists = compleads['RMLeads'];
      }else{
        this.filterLoader = false;
        this.overduescounts = "0";
        this.overdueslists = ['']
      }
    });
      // Total Overdues Counts Fetch

  }



  filteredexecutives: any;

  filterhere() {
    this.fromdate = $('#fromdate').val();
    this.todate = $('#todate').val();
    if(this.fromdate == "" || this.todate == ""){
      this.router.navigate([], {
        queryParams: {
          from: "",
          to: ""
        },
        queryParamsHandling: 'merge',
      });
    }else if(this.fromdate == undefined || this.todate == undefined){
      this.router.navigate([], {
        queryParams: {
          from: "",
          to: ""
        },
        queryParamsHandling: 'merge',
      });
    }
    else{
      NewdashboardComponent.closedcount = 0;
      NewdashboardComponent.count = 0;
      // this.filterLoader = true;
      this.filterdata = true;
      this.datefilterview = true;
      this.actionid = "";
      // this.batch2trigger();
      this.router.navigate([], {
        queryParams: {
          from: this.fromdate,
          to: this.todate
        },
        queryParamsHandling: 'merge',
      });
      // if(this.allvisitparam == '1'){
      //   this.allvisits();
      // }else if(this.inprogressparam == '1'){
      //   this.inprogresstrigger();
      // }else if(this.interestedparam == '1'){
      //   this.interestedtrigger();
      // }else if(this.junkparam == '1'){
      //   this.junkclick();
      // }else if(this.bookedparam == '1'){
      //   this.bookedclick();
      // }else if(this.bookingreqparam == '1'){
      //   this.bookingreqclick();
      // }else if(this.rejectreqparam == '1'){
      //   this.rejectreqclick();
      // }
    }
    $('.modalclick').removeClass('modal-backdrop');
    $('.modalclick').removeClass('fade');
    $('.modalclick').removeClass('show');
    document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
    
  }

  dateclose(){
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.datefilterview = false;
    $('#fromdate').val("");
    $('#todate').val("");
    this.fromdate = "";
    this.todate = "";
    this.actionid = "";
    // this.batch2trigger();
    this.router.navigate([], {
      queryParams: {
        from: "",
        to: ""
      },
      queryParamsHandling: 'merge',
    });
    // if(this.allvisitparam == '1'){
    //   this.allvisits();
    // }else if(this.inprogressparam == '1'){
    //   this.inprogresstrigger();
    // }else if(this.interestedparam == '1'){
    //   this.interestedtrigger();
    // }else if(this.junkparam == '1'){
    //   this.junkclick();
    // }else if(this.bookedparam == '1'){
    //   this.bookedclick();
    // }else if(this.bookingreqparam == '1'){
    //   this.bookingreqclick();
    // }else if(this.rejectreqparam == '1'){
    //   this.rejectreqclick();
    // }
  }

  rmchange(vals) {
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.executivefilterview = true;
    if(vals.target.value == 'all'){
      this.executivefilterview = false;
      if(this.roleid == '1'){
        this.execid = "";
        this.rmid = "";
      }else{
        this.execid = "All";
        this.rmid = "All";
      }
      this.execname = "";
      this.router.navigate([], {
        queryParams: {
          execid: this.execid,
          execname: ""
        },
        queryParamsHandling: 'merge',
      });
    }else if(vals.target.value == 'Others'){
      this.executivefilterview = false;
      if(this.roleid == '1'){
        this.execid = "";
        this.rmid = "";
      }else{
        this.execid = "others";
        this.rmid = "others";
      }
      this.execname = "";
      this.router.navigate([], {
        queryParams: {
          execid: this.execid,
          execname: ""
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.execid = vals.target.value;
      this.rmid = this.execid;
      this.execname = vals.target.options[vals.target.options.selectedIndex].text;
      this.router.navigate([], {
        queryParams: {
          execid: this.execid,
          execname: this.execname
        },
        queryParamsHandling: 'merge',
      });
    }
    
  }
  mandateexecutives: any;
  propchange(vals){
    var element = document.getElementById('filtermaindiv');
    this.filterdata = true;
    this.propertyfilterview = true;
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    
    if(vals.target.value == 'all'){
      this.propertyfilterview = false;
      this.propertyid = "";
      this.propertyname = "";
      this.router.navigate([], {
        queryParams: {
          property: "",
          propname: "",
          team: "",
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.propertyid = vals.target.value;
      this.propertyname = vals.target.options[vals.target.options.selectedIndex].text;
      this.router.navigate([], {
        queryParams: {
          property: this.propertyid,
          propname: vals.target.options[vals.target.options.selectedIndex].text,
          team: "",
        },
        queryParamsHandling: 'merge',
      });
    }
  }


  teamchange(vals)
  {
    if(vals.target.value == 'all'){
      this.team = "";
      this.router.navigate([], {
        queryParams: {
          team: "",
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.team = vals.target.value;
      this.router.navigate([], {
        queryParams: {
          team: this.team
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  statuschange(vals){
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.stagefilterview = true;
    this.stagestatusval = "";
    this.stagestatusvaltext = "";
    this.stagevalue = vals.target.value;
    
    // this.apitrigger();
    if(this.stagevalue == "USV"){
      this.stagestatus = false;
      this.router.navigate([], {
        queryParams: {
          stage: vals.target.value,
          stagestatus: ""
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.stagestatus = true;
      this.router.navigate([], {
        queryParams: {
          stage: vals.target.value,
        },
        queryParamsHandling: 'merge',
      });
      $("#option-4").prop( "checked", false );
      $("#option-5").prop( "checked", false );
      $("#option-6").prop( "checked", false );
    }
  }

  stagestatuschange(vals){
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.stagestatusfilterview = true;
    this.stagestatusval = vals.target.value;
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        stagestatus: vals.target.value,
      },
      queryParamsHandling: 'merge',
    });
    if(this.stagestatusval == '1'){
      this.stagestatusvaltext = "Fixed";
    }else if(this.stagestatusval == '2'){
      this.stagestatusvaltext = "Refixed";
    }else if(this.stagestatusval == '3'){
      this.stagestatusvaltext = "Done";
    }
  }

  executiveclose(){
    
    $('#rm_dropdown').dropdown('clear');
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.executivefilterview = false;
    this.execid = "";
    this.execname = "";
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        execid: "",
        execname: ""
      },
      queryParamsHandling: 'merge',
    });

  }

  propertyclose()
  {
    $('#project_dropdown').dropdown('clear');
    this.propertyfilterview = false;
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    this.propertyid = "";
    this.stagestatusval = "";
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        property: "",
        propname: ""
      },
      queryParamsHandling: 'merge',
    });
  }

  stageclose(){
    NewdashboardComponent.closedcount = 0;
    NewdashboardComponent.count = 0;
    $("#option-1").prop( "checked", false );
    $("#option-2").prop( "checked", false );
    $("#option-3").prop( "checked", false );
    $("#option-4").prop( "checked", false );
    $("#option-5").prop( "checked", false );
    $("#option-6").prop( "checked", false );
    this.stagefilterview = false;
    this.stagestatus = false;
    this.stagevalue = "";
    this.stagestatusval = "";
    this.router.navigate([], {
      queryParams: {
        stage: "",
        stagestatus:""
      },
      queryParamsHandling: 'merge',
    });
    // this.apitrigger();
  }

  leaddetails = false;
  rmleaddetails = false;
  roleid:any;
  userid:any;
  leadtrack:any;

  backToWelcome(){
    this.router.navigate(['/login']);
  }

  logout(){
    localStorage.clear();
    setTimeout(() => this.backToWelcome(), 1000);
    
  }

  scripts(){
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="tooltip"]').tooltip();
    $('.ui.dropdown').dropdown();
    $('#reportrange').daterangepicker({
      maxDate: new Date(),
    });
    $('#reportrange').on('apply.daterangepicker', function (ev, picker) {
      this.filterLoader = true;
      this.fromdate = convert(picker.startDate._d.toString());
      this.todate = convert(picker.endDate._d.toString());
      // window.location.replace('http://localhost:5600/dashboard?allvisits=1&from='+this.fromdate+'&to='+this.todate);
      // window.location.replace('https://crmsales.right2shout.in/dashboard?allvisits=1&from='+this.fromdate+'&to='+this.todate+'&property='+this.propertyid+'&propname='+this.propertyname+'&execid='+this.execid+'&execname='+this.execname+'&team='+this.team);
      window.location.replace('https://crmsales.right2shout.in/dashboard?allvisits=1&from='+this.fromdate+'&to='+this.todate);
      
      // this.router.navigate([], {
      //   queryParams: {
      //     property: this.propertyid,
      //     propname: this.propertyname,
      //     execid: this.execid,
      //     execname: this.execname,
      //     team: this.team,
      //     from: this.fromdate,
      //     to: this.todate,
      //     stage:this.stagevalue,
      //     stagestatus:this.stagestatusval,
      //   },
      //   queryParamsHandling: 'merge',
      // });

    });

    // $('.daterangepicker .applyBtn').click(function(){
    //   alert("Triggered");
    //     this.router.navigate([], {
    //     queryParams: {
    //       from: this.fromdate,
    //       to: this.todate
    //     },
    //     queryParamsHandling: 'merge',
    //   });
    // });

  }

  // triggerclick(){
  //   alert("success");
  // }

}

export function convert(str) {
  var mnths = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12"
    },
    date = str.split(" ");

  return [date[3], mnths[date[1]], date[2]].join("-");
}
