import { Component, OnInit, ElementRef, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { phpservice } from '../../php.service';
import { Followups } from './followup';
declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-followupform',
  templateUrl: './followupform.component.html',
  styleUrls: ['./followupform.component.css']
})
export class FollowupformComponent implements OnInit {

  date = new Date();
  priorDate = new Date(new Date().setDate(this.date.getDate() + 30));
  priorDatebefore = new Date(new Date().setDate(this.date.getDate() - 30));
  followmodel = new Followups();

  constructor(private router: Router,
    private route: ActivatedRoute,private _location: Location, private _phpService:phpservice) { }
    followupsections: any;
    executeid: any;
    csid: any;
    status:any;
    id = this.route.snapshot.params['id'];
    followsectiondata: any;
    followsectionname: any;
    currentstage: any;
    autoremarks: any;
    userid: any;
    filterLoader: boolean = true;
    stagestatus:any;
    stagestatusapi:any;
    selectedpropertylists: any;
    suggestchecked: any;
    leadid = this.route.snapshot.params['id'];

  ngOnInit() {
    this.userid = localStorage.getItem('UserId');
    this.scriptfunctions();
    this.loadimportantapi();
    this._phpService
      .getfollowupsections()
      .subscribe(followupsection => {
        this.followupsections = followupsection;
      });

      this._phpService
  .getassignedrm(this.id,this.userid)
  .subscribe(cust => {
     this.executeid = cust[0].executiveid;
     this.suggestchecked = cust[0]['suggestedprop'][0].propid;
  })
    // this._phpService
    // .getassignedcs(this.id)
    // .subscribe(cust => {
    //    this.csid = cust[0].executiveid;
    // })
  this.followsectiondata = "";
  this._phpService
      .getactiveleadsstatus(this.id,this.userid)
      .subscribe(stagestatus => {
        this.filterLoader = false;
        if(stagestatus.status == "True"){
          this.currentstage = stagestatus['activeleadsstatus'][0].stage;
          this.stagestatusapi = stagestatus['activeleadsstatus'][0].stagestatus;
        }else{
          this.currentstage = "Lead Stage";
          this.stagestatus = '0';
          // this.stagestatusapi = '';
        }
        
      });
  }

  scriptfunctions(){
    $('.ui.dropdown').dropdown();
    $('.calendardate').calendar({
      type: 'date',
      minDate: this.date,
      maxDate: this.priorDate,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
    $('.calendartime').calendar({
      type: 'time'
    });
    $.extend($.expr[':'], {
      unchecked: function (obj) {
          return ((obj.type == 'checkbox' || obj.type == 'radio') && !$(obj).is(':checked'));
      }
  }
);
  }

  loadimportantapi()
  {
    this._phpService
  .getmandateselectedsuggestproperties(this.leadid,this.userid)
  .subscribe(selectsuggested => {
    this.selectedpropertylists = selectsuggested['selectedlists'];
    // this.suggestchecked = this.selectedpropertylists.map((item) => { return item.propid }).join(',');
  });
  }

  followupactionclick(i,id,name){

    $(".actions").addClass("actionbtns");
    $(".selectMark").addClass("iconmark");
    $(".actionbtns").removeClass("actions");
    $(".iconmark").removeClass("selectMark");

    $(".actions"+i).removeClass("actionbtns");
    $(".actions"+i).addClass("actions");
    $(".selectMark"+i).removeClass("iconmark");
    $(".selectMark"+i).addClass("selectMark");

    this.followsectiondata = id;
    this.followsectionname = name;
  }

  

  addfollowupdata(){
    var followdate = $('#folloupdate').val();
    var followtime = $('#followuptime').val();
    var leadstage = $('#sectionselector').val();
    var followuptextarearemarks = $('#followuptextarearemarks').val();
    var leadid = this.id;
    var userid = localStorage.getItem('UserId');
    var username = localStorage.getItem('Name');
    if(this.currentstage !== 'Lead Stage'){
      if(this.stagestatusapi == '1'){
        this.stagestatus = "1";
      }else if(this.stagestatusapi == '2'){
        this.stagestatus = "2";
      }else if(this.stagestatusapi == '3'){
        this.stagestatus = "3";
      }
    }else{
      if(this.stagestatusapi == null){
        this.stagestatus = '0';
      }else{
        this.stagestatus = this.stagestatusapi;
      }
    }
    var dateformatchange = new Date(followdate).toDateString();

    var followupscommon = {
      leadid: leadid,
      actiondate: followdate,
      actiontime: followtime,
      leadstatus: leadstage,
      stagestatus: '3',
      followupsection: this.followsectiondata,
      followupremarks: followuptextarearemarks,
      userid: userid,
      assignid: this.executeid,
      property: this.suggestchecked,
      autoremarks: " Set the next followup on - "+dateformatchange+" "+followtime
    }

    // USV DONE with Followup Fixing

    if( $('#sectionselector').val() == "USV"){

      if($('#visitupdate').val() == "1"){
        // this.visitupdate = "Visited";
      }else if($('#visitupdate').val() == "3"){
        // this.visitupdate = "Visited but not interested";
      }else{
        // this.visitupdate = "Not Visited";
      }
      // this.propertyremarks = $('#propertyremarks').val();
      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }

      if ($('#USVvisiteddate').val() == "") {
        $('#USVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisiteddate').removeAttr("style");
      }
  
      if ($('#USVvisitedtime').val() == "") {
        $('#USVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#USVvisitedtime').removeAttr("style");
      }

     
  
      // if ($('#usvfinalremarks').val() == "") {
      //   $('#usvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#usvfinalremarks').removeAttr("style");
      // }
      if(this.followsectiondata == "")
            {
              swal({
                title: 'Please Select any Followup Actions',
                type: 'error',
                confirmButtonText: 'ok'
                })
              return false;
            }
      else{    
        $('#followupsection').removeAttr("style"); 
            }
    if($('#folloupdate').val()=="")
            {
              $('#folloupdate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#folloupdate').removeAttr("style"); 
            }
            if($('#followuptime').val()=="")
            {
              $('#followuptime').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#followuptime').removeAttr("style"); 
            }
            if($('#followuptextarearemarks').val()=="")
            {
              $('#followuptextarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
              return false;
            }
      else{    
        $('#followuptextarearemarks').removeAttr("style"); 
            }

            if(this.followsectionname == "Callback"){
              this.autoremarks = " set the status as Followup after the USV, because client need a callback.";
            }else if(this.followsectionname == "RNR"){
              this.autoremarks = " tried to contact the client after the USV but, client didnt pick the call. So its set as Followup.";
            }else if(this.followsectionname == "Switch Off"){
              this.autoremarks = " tried to contact the client after the USV but, number is swtiched off. So its set as Followup.";
            }else if(this.followsectionname == "Not Connected"){
              this.autoremarks = " tried to contact the client after the USV but, number is not connecting. So its set as Followup.";
            }else if(this.followsectionname == "Number Busy"){
              this.autoremarks = " tried to contact the client after the USV but, number is busy. So its set as Followup.";
            }else if(this.followsectionname == "Not Answered"){
              this.autoremarks = " tried to contact the client after the USV but, client is not answering the call. So its set as Followup.";
            }else if(this.followsectionname == "Not Reachable"){
              this.autoremarks = " tried to contact the client after the USV but, number is in out of coverage area. So its set as Followup.";
            }else if(this.followsectionname == "Fix SiteVisit"){
              this.autoremarks = " set the status as Followup for fixing the next sitevisit.";
            }else{
              this.autoremarks = " Changed the status to Followup after the USV - "+this.followsectionname;
            }

            // parameters & API Submissions for the property sitevisit update
            var visitparam = {
              leadid: this.id,
              propid: this.suggestchecked,
              execid: this.userid,
              visitupdate: $('#visitupdate').val(),
              remarks: $('#propertyremarks').val(),
              stage: "USV",
            }
            // parameters & API Submissions for the property sitevisit update

            var visiteddate = $('#USVvisiteddate').val();
            var visitedtime = $('#USVvisitedtime').val();
            // var usvfinalremarks = $('#usvfinalremarks').val();
            var usvfinalremarks = "USV Done";

            var leadusvdoneparam = {
              leadid: this.id,
              closedate: visiteddate,
              closetime: visitedtime,
              leadstage: "USV",
              stagestatus: '3',
              textarearemarks: usvfinalremarks,
              userid: userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this.filterLoader = true;

            this._phpService.addpropertyvisitupdate(visitparam).subscribe((success) => {
              this.status = success.status;
              if (this.status == "True") {
                $('#visitupdate').val("4");
                this._phpService.addleadhistory(leadusvdoneparam).subscribe((success)=> {
                  this.status = success.status;
                  if(this.status == "True"){
                    this._phpService.addfollowuphistory(followupscommon).subscribe((success)=> {
                      this.status = success.status;
                      if(this.status == "True"){
                        this.filterLoader = false;
                      swal({
                        title: 'Followup Fixed Succefully',
                        text: "Please check your followup bucket for the Lead reminders",
                        type: "success",
                        confirmButtonText: 'OK!'
                      }).then((result) => {
                        if (result.value) {
                          // location.reload();
                          // this.ngOnInit();
                          // this._phpService.mouseenterservice();
                          let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                        }
                      });
                      }
                    },(err) => {
                      console.log("Failed to Update");
                    })
                  }
                },(err) => {
                  console.log("Failed to Update");
                });
              }
            }, (err) => {
              console.log("Failed to Update");
            })

            

    }

    // USV DONE with Followup Fixing

    // SV DONE with Followup Fixing

    else if( $('#sectionselector').val() == "SV"){

      if($('#visitupdate').val() == "1"){
        // this.visitupdate = "Visited";
      }else if($('#visitupdate').val() == "3"){
        // this.visitupdate = "Visited but not interested";
      }else{
        // this.visitupdate = "Not Visited";
      }
      // this.propertyremarks = $('#propertyremarks').val();
      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#SVvisiteddate').val() == "") {
        $('#SVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisiteddate').removeAttr("style");
      }
  
      if ($('#SVvisitedtime').val() == "") {
        $('#SVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#SVvisitedtime').removeAttr("style");
      }
  
      // if ($('#svfinalremarks').val() == "") {
      //   $('#svfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the SV');
      //   return false;
      // }
      // else {
      //   $('#svfinalremarks').removeAttr("style");
      // }

      if(this.followsectiondata == "")
            {
              swal({
                title: 'Please Select any Followup Actions',
                type: 'error',
                confirmButtonText: 'ok'
                })
              return false;
            }
      else{    
        $('#followupsection').removeAttr("style"); 
            }
    if($('#folloupdate').val()=="")
            {
              $('#folloupdate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#folloupdate').removeAttr("style"); 
            }
            if($('#followuptime').val()=="")
            {
              $('#followuptime').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#followuptime').removeAttr("style"); 
            }
            if($('#followuptextarearemarks').val()=="")
            {
              $('#followuptextarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
              return false;
            }
      else{    
        $('#followuptextarearemarks').removeAttr("style"); 
            }

            if(this.followsectionname == "Callback"){
              this.autoremarks = " set the status as Followup after the SV, because client need a callback.";
            }else if(this.followsectionname == "RNR"){
              this.autoremarks = " tried to contact the client after the SV but, client didnt pick the call. So its set as Followup.";
            }else if(this.followsectionname == "Switch Off"){
              this.autoremarks = " tried to contact the client after the SV but, number is swtiched off. So its set as Followup.";
            }else if(this.followsectionname == "Not Connected"){
              this.autoremarks = " tried to contact the client after the SV but, number is not connecting. So its set as Followup.";
            }else if(this.followsectionname == "Number Busy"){
              this.autoremarks = " tried to contact the client after the SV but, number is busy. So its set as Followup.";
            }else if(this.followsectionname == "Not Answered"){
              this.autoremarks = " tried to contact the client after the SV but, client is not answering the call. So its set as Followup.";
            }else if(this.followsectionname == "Not Reachable"){
              this.autoremarks = " tried to contact the client after the SV but, number is in out of coverage area. So its set as Followup.";
            }else if(this.followsectionname == "Fix SiteVisit"){
              this.autoremarks = " set the status as Followup for fixing the next sitevisit.";
            }else{
              this.autoremarks = " Changed the status to Followup after the SV - "+this.followsectionname;
            }

        var visiteddate = $('#SVvisiteddate').val();
        var visitedtime = $('#SVvisitedtime').val();
        // var svfinalremarks = $('#svfinalremarks').val();
        var svfinalremarks = "SV Done";

        var leadsvdoneparam = {
          leadid: this.id,
          closedate: visiteddate,
          closetime: visitedtime,
          leadstage: "SV",
          stagestatus: '3',
          textarearemarks: svfinalremarks,
          userid: userid,
          assignid: this.executeid,
          autoremarks: this.autoremarks,
          property: this.suggestchecked
        }
        this.filterLoader = true;
        this._phpService.addleadhistory(leadsvdoneparam).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            this._phpService.addfollowuphistory(followupscommon).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
              swal({
                title: 'Followup Updated Succefully',
                text: "Please check your followup bucket for the Lead reminders",
                type: "success",
                confirmButtonText: 'OK!'
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                          this.router.navigate([currentUrl]);
                      });
                }
              });
              }
            },(err) => {
              console.log("Failed to Update");
            })
          }
        },(err) => {
          console.log("Failed to Update");
        });

    }

    // SV DONE with Followup Fixing

    // RSV DONE with Followup Fixing

    else if( $('#sectionselector').val() == "RSV"){

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please select any actions',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
        $('#visitupdate').removeAttr("style");
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      
      if ($('#RSVvisiteddate').val() == "") {
        $('#RSVvisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisiteddate').removeAttr("style");
      }
  
      if ($('#RSVvisitedtime').val() == "") {
        $('#RSVvisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#RSVvisitedtime').removeAttr("style");
      }
  
      // if ($('#rsvfinalremarks').val() == "") {
      //   $('#rsvfinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the USV');
      //   return false;
      // }
      // else {
      //   $('#rsvfinalremarks').removeAttr("style");
      // }

      if(this.followsectiondata == "")
            {
              swal({
                title: 'Please Select any Followup Actions',
                type: 'error',
                confirmButtonText: 'ok'
                })
              return false;
            }
      else{    
        $('#followupsection').removeAttr("style"); 
            }
    if($('#folloupdate').val()=="")
            {
              $('#folloupdate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#folloupdate').removeAttr("style"); 
            }
            if($('#followuptime').val()=="")
            {
              $('#followuptime').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#followuptime').removeAttr("style"); 
            }
            if($('#followuptextarearemarks').val()=="")
            {
              $('#followuptextarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
              return false;
            }
      else{    
        $('#followuptextarearemarks').removeAttr("style"); 
            }

            if(this.followsectionname == "Callback"){
              this.autoremarks = " set the status as Followup after the RSV, because client need a callback.";
            }else if(this.followsectionname == "RNR"){
              this.autoremarks = " tried to contact the client after the RSV but, client didnt pick the call. So its set as Followup.";
            }else if(this.followsectionname == "Switch Off"){
              this.autoremarks = " tried to contact the client after the RSV but, number is swtiched off. So its set as Followup.";
            }else if(this.followsectionname == "Not Connected"){
              this.autoremarks = " tried to contact the client after the RSV but, number is not connecting. So its set as Followup.";
            }else if(this.followsectionname == "Number Busy"){
              this.autoremarks = " tried to contact the client after the RSV but, number is busy. So its set as Followup.";
            }else if(this.followsectionname == "Not Answered"){
              this.autoremarks = " tried to contact the client after the RSV but, client is not answering the call. So its set as Followup.";
            }else if(this.followsectionname == "Not Reachable"){
              this.autoremarks = " tried to contact the client after the RSV but, number is in out of coverage area. So its set as Followup.";
            }else if(this.followsectionname == "Fix SiteVisit"){
              this.autoremarks = " set the status as Followup for fixing the next sitevisit.";
            }else{
              this.autoremarks = " Changed the status to Followup after the RSV - "+this.followsectionname;
            }
            // parameters & API Submissions for the property sitevisit update
            var visitedparam = {
              leadid: this.leadid,
              propid: this.suggestchecked,
              execid: this.userid,
              visitupdate: $('#visitupdate').val(),
              remarks: $('#propertyremarks').val(),
              stage: "RSV",
            }
            // parameters & API Submissions for the property sitevisit update

        var visiteddate = $('#RSVvisiteddate').val();
        var visitedtime = $('#RSVvisitedtime').val();
        // var rsvfinalremarks = $('#rsvfinalremarks').val();
        var rsvfinalremarks = "RSV Done";

        var leadrsvdoneparam = {
          leadid: this.id,
          closedate: visiteddate,
          closetime: visitedtime,
          leadstage: "RSV",
          stagestatus: '3',
          textarearemarks: rsvfinalremarks,
          userid: userid,
          assignid: this.executeid,
          autoremarks: this.autoremarks,
          property: this.suggestchecked
        }
        this.filterLoader = true;
        this._phpService.addpropertyvisitupdate(visitedparam).subscribe((success) => {
          this.status = success.status;
          if (this.status == "True") {
            this._phpService.addleadhistory(leadrsvdoneparam).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this._phpService.addfollowuphistory(followupscommon).subscribe((success)=> {
                  this.status = success.status;
                  if(this.status == "True"){
                    this.filterLoader = false;
                  swal({
                    title: 'Followup Updated Succefully',
                    text: "Please check your followup bucket for the Lead reminders",
                    type: "success",
                    confirmButtonText: 'OK!'
                  }).then((result) => {
                    if (result.value) {
                      let currentUrl = this.router.url;
                          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                              this.router.navigate([currentUrl]);
                          });
                    }
                  });
                  }
                },(err) => {
                  console.log("Failed to Update");
                })
              }
            },(err) => {
              console.log("Failed to Update");
            });
          }
        }, (err) => {
          console.log("Failed to Update");
        })

      

    }

    // RSV DONE with Followup Fixing

    // NEGOTIATION DONE with Followup Fixing

    else if( $('#sectionselector').val() == "Final Negotiation"){

      if ($('#visitupdate').val() == "") {
        swal({
          title: 'Action Not Took',
          text: 'Please Confirm Property Revisited or Not',
          type: 'error',
          confirmButtonText: 'OK'
        })
        return false;
      }
      else {
      }
  
      if ($('#propertyremarks').val() == "") {
        $('#propertyremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Sitevisit');
        return false;
      }
      else {
        $('#propertyremarks').removeAttr("style");
      }
      if ($('#negovisiteddate').val() == "") {
        $('#negovisiteddate').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisiteddate').removeAttr("style");
      }
  
      if ($('#negovisitedtime').val() == "") {
        $('#negovisitedtime').focus().css("border-color", "red").attr('placeholder', 'Please Select One Date');
        return false;
      }
      else {
        $('#negovisitedtime').removeAttr("style");
      }
  
      // if ($('#negofinalremarks').val() == "") {
      //   $('#negofinalremarks').focus().css("border-color", "red").attr('placeholder', 'Please add some remarks about the Negotiation');
      //   return false;
      // }
      // else {
      //   $('#negofinalremarks').removeAttr("style");
      // }

      if(this.followsectiondata == "")
            {
              swal({
                title: 'Please Select any Followup Actions',
                type: 'error',
                confirmButtonText: 'ok'
                })
              return false;
            }
      else{    
        $('#followupsection').removeAttr("style"); 
            }
    if($('#folloupdate').val()=="")
            {
              $('#folloupdate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#folloupdate').removeAttr("style"); 
            }
            if($('#followuptime').val()=="")
            {
              $('#followuptime').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#followuptime').removeAttr("style"); 
            }
            if($('#followuptextarearemarks').val()=="")
            {
              $('#followuptextarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
              return false;
            }
      else{    
        $('#followuptextarearemarks').removeAttr("style"); 
            }

            if(this.followsectionname == "Callback"){
              this.autoremarks = " set the status as Followup after the Finalnegotiation, because client need a callback.";
            }else if(this.followsectionname == "RNR"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, client didnt pick the call. So its set as Followup.";
            }else if(this.followsectionname == "Switch Off"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, number is swtiched off. So its set as Followup.";
            }else if(this.followsectionname == "Not Connected"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, number is not connecting. So its set as Followup.";
            }else if(this.followsectionname == "Number Busy"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, number is busy. So its set as Followup.";
            }else if(this.followsectionname == "Not Answered"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, client is not answering the call. So its set as Followup.";
            }else if(this.followsectionname == "Not Reachable"){
              this.autoremarks = " tried to contact the client after the Finalnegotiation but, number is in out of coverage area. So its set as Followup.";
            }else if(this.followsectionname == "Fix SiteVisit"){
              this.autoremarks = " set the status as Followup for fixing the next sitevisit.";
            }else{
              this.autoremarks = " Changed the status to Followup after the Finalnegotiation - "+this.followsectionname;
            }

            var negovisitparam = {
              leadid: this.leadid,
              propid: this.suggestchecked,
              execid: this.userid,
              visitupdate: $('#visitupdate').val(),
              remarks: $('#propertyremarks').val(),
              stage: "Final Negotiation",
            }

          var visiteddate = $('#negovisiteddate').val();
          var visitedtime = $('#negovisitedtime').val();
          // var negofinalremarks = $('#negofinalremarks').val();
          var negofinalremarks = "Final Negotiation Finished";

          var leadnegodoneparam = {
            leadid: this.id,
            closedate: visiteddate,
            closetime: visitedtime,
            leadstage: "Final Negotiation",
            stagestatus: '3',
            textarearemarks: negofinalremarks,
            userid: userid,
            assignid: this.executeid,
            autoremarks: this.autoremarks,
            property: this.suggestchecked
          }

      this.filterLoader = true;
      this._phpService.addpropertyvisitupdate(negovisitparam).subscribe((success) => {
        this.status = success.status;
      if (this.status == "True"){
        this._phpService.addleadhistory(leadnegodoneparam).subscribe((success)=> {
          this.status = success.status;
          if(this.status == "True"){
            this._phpService.addfollowuphistory(followupscommon).subscribe((success)=> {
              this.status = success.status;
              if(this.status == "True"){
                this.filterLoader = false;
              swal({
                title: 'Followup Updated Succefully',
                text: "Please check your followup bucket for the Lead reminders",
                type: "success",
                confirmButtonText: 'OK!'
              }).then((result) => {
                if (result.value) {
                  let currentUrl = this.router.url;
                        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                            this.router.navigate([currentUrl]);
                        });
                }
              });
              }
            },(err) => {
              console.log("Failed to Update");
            })
          }
        },(err) => {
          console.log("Failed to Update");
        });
      }
      }, (err) => {
        console.log("Failed to Update");
      })

      

    }

    // NEGOTIATION DONE with Followup Fixing

    
    // Direct Followup Fixing

    else{

      if(this.followsectiondata == "")
            {
              swal({
                title: 'Please Select any Followup Actions',
                type: 'error',
                confirmButtonText: 'ok'
                })
              return false;
            }
      else{    
        $('#followupsection').removeAttr("style"); 
            }
    if($('#folloupdate').val()=="")
            {
              $('#folloupdate').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#folloupdate').removeAttr("style"); 
            }
            if($('#followuptime').val()=="")
            {
              $('#followuptime').focus().css("border-color","red").attr('placeholder','Please Select One Date');
              return false;
            }
      else{    
        $('#followuptime').removeAttr("style"); 
            }
            if($('#followuptextarearemarks').val()=="")
            {
              $('#followuptextarearemarks').focus().css("border-color","red").attr('placeholder','Please add some remarks');
              return false;
            }
      else{    
        $('#followuptextarearemarks').removeAttr("style"); 
            }
            var followleadstage = this.currentstage;
            if(this.followsectionname == "Callback"){
              this.autoremarks = " Changed the status to Followup, because client need a callback.";
            }else if(this.followsectionname == "RNR"){
              this.autoremarks = " tried to contact the client but, client didnt pick the call. So Changed the status to Followup.";
            }else if(this.followsectionname == "Switch Off"){
              this.autoremarks = " tried to contact the client but, number is swtiched off. So Changed the status to Followup.";
            }else if(this.followsectionname == "Not Connected"){
              this.autoremarks = " tried to contact the client but, number is not connecting. So Changed the status to Followup.";
            }else if(this.followsectionname == "Number Busy"){
              this.autoremarks = " tried to contact the client but, number is busy. So Changed the status to Followup.";
            }else if(this.followsectionname == "Not Answered"){
              this.autoremarks = " tried to contact the client but, client is not answering the call. So Changed the status to Followup.";
            }else if(this.followsectionname == "Not Reachable"){
              this.autoremarks = " tried to contact the client but, number is in out of coverage area. So Changed the status to Followup.";
            }else if(this.followsectionname == "Fix SiteVisit"){
              this.autoremarks = " Changed the status to Followup, Need to callback the client for fix the sitevisit.";
            }else{
              this.autoremarks = " Changed the status to Followup - "+this.followsectionname;
            }

            var followups = {
              leadid: leadid,
              actiondate: followdate,
              actiontime: followtime,
              leadstatus: this.currentstage,
              stagestatus: this.stagestatus,
              followupsection: this.followsectiondata,
              followupremarks: followuptextarearemarks,
              userid: userid,
              assignid: this.executeid,
              autoremarks: this.autoremarks,
              property: this.suggestchecked
            }
            this.filterLoader = true;
      this._phpService.addfollowuphistory(followups).subscribe((success)=> {
        this.status = success.status;
        if(this.status == "True"){
          this.filterLoader = false;
        swal({
          title: 'Followup Updated Succefully',
          text: "Please check your followup bucket for the Lead reminders",
          type: "success",
          confirmButtonText: 'OK!'
        }).then((result) => {
          if (result.value) {
            // this._phpService.mouseenterservice();
            let currentUrl = this.router.url;
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                this.router.navigate([currentUrl]);
            });
          }
        });
        }
      },(err) => {
        console.log("Failed to Update");
      })

    }

    // Direct Followup Fixing
    

  };

}
