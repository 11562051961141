import { Component, OnInit } from '@angular/core';
import { phpservice } from '../../php.service';
import { leadforward, reassignlead } from './leadassign';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;
declare var swal: any;
declare const navigator: any; 

@Component({
  selector: 'app-leadassign',
  templateUrl: './leadassign.component.html',
  styleUrls: ['./leadassign.component.css']
})
export class LeadassignComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, private _phpService: phpservice, public datepipe: DatePipe) {
    setTimeout(() => {
      $('.ui.dropdown').dropdown();
      $('.ui.label.fluid.dropdown').dropdown({
        useLabels: false
      });
    }, 1000);
  }
  private isCountdownInitialized: boolean;

  filterLoader: boolean = true;

  Executivename = "";
  rmpaneldata = false;
  rmdata = false;
  // cspaneldata = false;
  // csdata = false;
  assignedleadscounts: any;
  fromdate: any;
  todate: any;
  rmid: any;
  execid: any;
  propertyid: any;
  propertyname: any;
  // csid: any;
  activeleadscount: any;
  tookactionmenu = false;
  activemenu = false;
  followupmenu = false;
  assignedleadstable = true;
  tookactiontable = false;
  closedtable = false;
  followuptable = false;
  followupcounts: any;
  stagesections: any;
  tookaction: any;
  closedleads: any;
  followups: any;
  followupsectionid: any;
  static count: number;
  static activecount: number;
  static closedcount: number;
  static followupscount: number;
  static reassignedcount: number;
  callerleads: any;
  callerleadscounts: any;
  telecallers: any;
  leads: any;
  leadforwards = new leadforward();
  reassignleadforwards = new leadforward();
  status: any;
  leadreassign = new reassignlead();
  executives: any;
  actionid: any;
  loadmorecountactivecounts: any;
  loadmorecountfollowups: any;
  loadmoreclosedcounts: any;

  totalcounts: any;
  allcountsloadmore: any;
  tookactioncounts: any;
  pendingcounts: any;
  activecount: any;
  inactivecount: any;
  f2fcount: any;
  usvcount: any;
  svcount: any;
  rsvcount: any;
  negocount: any;
  closedcount: any;
  leadreassigning: any;
  // csleadassign = false;
  rmleadassign = false;
  inactivetable = false;
  junkleads: any;
  onlyrm = false;
  onlycs = false;
  rmonly: any;
  csonly: any;
  followupsections:any;
  filterdata = false;
  teamfilterview = false;
  executivefilterview = false;
  propertyfilterview = false;
  stagefilterview = false;
  stagestatusfilterview = false;
  datefilterview = false;
  execname: any;
  fupsectionname: any;
  selecteduser: any;
  currentDate = new Date();
  todaysdate: any;
  enchanting = false;
  grsitara = false;
  todaystotalcounts: any;
  todayscheduled: any;
  upcomingvisits: any;
  upcomingfollowups: any;
  overdue: any;
  notinterested: any;
  interested: any;
  inprogress: any;
  booked: any;
  bookingrequest: any;
  rejected: any;
  retailmoved: any;
  junk = true;
  propertyparam: any;
  todaysscheduledparam: any;
  todaysvisitedparam: any;
  upcomingvisitparam: any;
  upcomingfollowupparam: any;
  overdueparam: any;
  junkparam: any;
  bookedparam: any;
  bookingreqparam:any;
  rejectreqparam:any;
  retailmovedparam: any;
  allvisitparam: any;
  inprogressparam: any;
  interestedparam: any;
  currentdateforcompare = new Date();
  todaysdateforcompare: any;
  yesterdaysdateforcompare: any;
  tomorrowsdateforcompare: any;
  activitypage: Boolean;
  allvisittracks: Boolean;
  filterview: Boolean;
  stagevalue: any;
  stagestatusval: any;
  stagestatusvaltext: any;
  stagestatus = false;
  retailmovelink = false;
  team: any;
  teamname: any;
  adminandexec: Boolean;
  mandateexecutives: any;

  ngOnInit() {
    this.roleid = localStorage.getItem('Role');
    this.userid = localStorage.getItem('UserId');
    if(this.userid == '40007'){
      this.adminandexec = true;
     }else if(this.userid == '40078'){
      this.adminandexec = true;
     }else if(this.userid == '40045'){
      this.adminandexec = true;
     }else if(this.userid == '40050'){
      this.adminandexec = true;
     }else if(this.userid == '40041'){
      this.adminandexec = true;
     }else if(this.userid == '1'){
       this.adminandexec = true;
     }else{
      this.adminandexec = false;
     }
    // *********************load the required template files*********************
    
    // *********************load the required template files*********************
    // this.getcustomerexec();
    this.getleadsdata();

    var curmonth = this.currentdateforcompare.getMonth()+1;
    var curmonthwithzero = curmonth.toString().padStart(2, "0");
    // Todays Date
    var curday = this.currentdateforcompare.getDate();
    var curdaywithzero = curday.toString().padStart(2, "0");
    this.todaysdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;
    // Todays Date
    // Yesterdays Date
    var yesterday = this.currentdateforcompare.getDate()-1;
    var yesterdaywithzero = yesterday.toString().padStart(2, "0");
    this.yesterdaysdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + yesterdaywithzero;
    // Yesterdays Date
    // Tomorrows Date
    var tomorrow = this.currentdateforcompare.getDate()+1;
    var tomorrowwithzero = tomorrow.toString().padStart(2, "0");
    this.tomorrowsdateforcompare = this.currentdateforcompare.getFullYear()  + "-" + curmonthwithzero + "-" + tomorrowwithzero;
    // Tomorrows Date
    
    
    if (localStorage.getItem('Role') == null) {
      this.router.navigateByUrl('/login');
    }
    if (localStorage.getItem('Role') == '1') {
      this.tookactiontable = true;
      this.assignedleadstable = false;
    }else if(localStorage.getItem('Role') == '50002'){

      this.rmid = localStorage.getItem('UserId');
      
    } else {
      this.tookactiontable = false;
      this.assignedleadstable = true;
    }
    // this._phpService
    //   .getfollowupsections()
    //   .subscribe(followupsection => {
    //     this.followupsections = followupsection;
    //   });
    const elements = document.getElementsByClassName("modalclick");
    while (elements.length > 0) elements[0].remove();
    const el = document.createElement('div');
    el.classList.add('modalclick');
    document.body.appendChild(el);
    LeadassignComponent.count = 0;
    LeadassignComponent.activecount = 0;
    LeadassignComponent.followupscount = 0;
    LeadassignComponent.reassignedcount = 0;
    LeadassignComponent.closedcount = 0;
    this.mandateprojectsfetch();

    
    // if($(".filterspan").children().length == 0){
    //   alert("Empty")
    // }else{
    //       alert("Not Empty");
    // }
  }

  mandateprojects: any;
  mandateprojectsfetch(){
    this._phpService.getmandateprojects().subscribe(mandates => { 
      if(mandates['status'] == 'True'){
        this.mandateprojects = mandates['mandateprojects'];
      }else{
        
      }
    });
  }

  ngAfterViewChecked()
  {
    if (!this.isCountdownInitialized){
      this.isCountdownInitialized = true;
      $('.modalclick').click(function(){
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
      });
    }
  }

  ngAfterViewInit()
  { 
    // After Page loaded Checking the filter div element has empty or not
    // if($("#filtermaindiv").children().length == 0){
    //   $('#filtermaindiv').addClass('absol');
    //   alert("Empty");
    // }else{
    //   $('#filtermaindiv').removeClass('absol');
    //   alert($("#filtermaindiv").children().length);
    // }
    // After Page loaded Checking the filter div element has empty or not
  }

  close()
  {
        $('.modalclick').removeClass('modal-backdrop');
        $('.modalclick').removeClass('fade');
        $('.modalclick').removeClass('show');
        document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
  }


  getleadsdata() {
    
    if(localStorage.getItem('Role') == '50002'){
      this.rmid = localStorage.getItem('UserId');
    }
    this.filterLoader = true;
    LeadassignComponent.count = 0;
    this.actionid = "";
    this.route.queryParams.subscribe((paramss) => {
      // Updated Using Strategy
      this.todaysscheduledparam = paramss['todayscheduled'];
      this.todaysvisitedparam = paramss['todayvisited'];
      this.upcomingvisitparam = paramss['upcomingvisits'];
      this.upcomingfollowupparam = paramss['upcomingfollowups'];
      this.overdueparam = paramss['overdue'];
      this.allvisitparam = paramss['allvisits'];
      this.inprogressparam = paramss['inprogress'];
      this.interestedparam = paramss['intrested'];
      this.junkparam = paramss['junks'];
      this.bookedparam = paramss['booked'];
      this.bookingreqparam = paramss['bookingrequest'];
      this.rejectreqparam = paramss['rejected'];
      this.retailmovedparam = paramss['retailmoved'];
      this.propertyid = paramss['property'];
      this.propertyname = paramss['propname'];
      this.execid = paramss['execid'];
      this.execname = paramss['execname'];
      this.stagevalue = paramss['stage'];
      this.fromdate = paramss['from'];
      this.todate = paramss['to'];
      this.stagestatusval = paramss['stagestatus'];
      this.team = paramss['team'];

      if(this.team){
        this.teamfilterview = true;
        if(this.team == '1'){
          this.teamname = 'Direct Team';
        }else{
          this.teamname = 'Inhouse Team';
        }
      }else{
        this.teamfilterview = false;
      }
      
      if(this.propertyid){
        this.propertyfilterview = true;
        if(this.propertyid == '34779'){
          this.enchanting = true;
          this.grsitara = false;
        }else if(this.propertyid == '16793'){
          this.grsitara = true;
          this.enchanting = false;
        }
        this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
          if(executives['status'] == 'True'){
            this.mandateexecutives = executives['mandateexecutives'];
          }else{
          }
        });
      }else{
        this.propertyfilterview = false;

        // ********************static method Need to change********************
        if(this.userid == '40007'){
          this.propertyid = '34779';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40078'){
          this.propertyid = '1830';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40045'){
          this.propertyid = '16793';
          this.team = '1';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40050'){
          this.propertyid = '1830';
          this.team = '1';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }else if(this.userid == '40041'){
          this.propertyid = '16793';
          this.team = '2';
          this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
            if(executives['status'] == 'True'){
              this.mandateexecutives = executives['mandateexecutives'];
            }else{
            }
          });
        }
        // else if(this.userid == '40073'){
        //   this.propertyid = '16793';
        //   this.team = '3';
        //   this._phpService.fetchmandateexecutuves(this.propertyid,this.team).subscribe(executives => { 
        //     if(executives['status'] == 'True'){
        //       this.mandateexecutives = executives['mandateexecutives'];
        //     }else{
        //     }
        //   });
        // }
        // ********************static method Need to change********************
      }

      if(this.execid){
        this.executivefilterview = true;
        if(localStorage.getItem('Role') == '1'){
          this.rmid = this.execid;
        }else{
          this.rmid = this.execid;
          // this.rmid = localStorage.getItem('UserId');
        }
      }else{
        this.executivefilterview = false;
        if(localStorage.getItem('Role') == '1'){
          this.rmid = "";
        }else{
          this.rmid = localStorage.getItem('UserId');
        }
      }

      if(this.stagevalue){
        this.stagefilterview = true;
        if(this.stagevalue == "USV"){
          this.stagestatus = false;
          this.stagestatusval = "";
        }else{
          this.stagestatus = true;
          $("#option-4").prop( "checked", false );
          $("#option-5").prop( "checked", false );
          $("#option-6").prop( "checked", false );
        }
      }else{
        this.stagefilterview = false;
      }

      if(this.fromdate || this.todate){
        this.datefilterview = true;
        $("#fromdate").val(this.fromdate);
        $("#todate").val(this.todate);
      }else{
        this.datefilterview = false;
      }

      if(this.stagestatusval){
        this.stagestatusfilterview = true;
        if(this.stagestatusval == '1'){
          this.stagestatusvaltext = "Fixed";
        }else if(this.stagestatusval == '2'){
          this.stagestatusvaltext = "Refixed";
        }else if(this.stagestatusval == '3'){
          this.stagestatusvaltext = "Done";
        }
      }else{
        this.stagestatusfilterview = false;
      }


      if(this.todaysscheduledparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.todayschedule();
      }else if(this.todaysvisitedparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.fetchtodaydata();
      }else if(this.upcomingvisitparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.upcomingvisit();
      }else if(this.upcomingfollowupparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.upcomingfollowup();
      }else if(this.overdueparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.overduesection();
      }else if(this.allvisitparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.allvisits();
      }else if(this.inprogressparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.inprogresstrigger();
      }else if(this.interestedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.interestedtrigger();
      }else if(this.junkparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        if(this.roleid == '1'){
          if(this.notinterested == '0'){
            this.retailmovelink = false;
          }else{
            this.retailmovelink = true;
          }
        }else{
          this.retailmovelink = false;
        }
        this.junkclick();
      }else if(this.bookedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.bookedclick();
      }else if(this.bookingreqparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.bookingreqclick();
      }else if(this.rejectreqparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.rejectreqclick();
      }else if(this.retailmovedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.retailmovedclick();
      }
      // Updated Using Strategy
    });
  }

  // Updated Using Strategy

  apitrigger(){
      if(this.todaysscheduledparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.todayschedule();
      }else if(this.todaysvisitedparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.fetchtodaydata();
      }else if(this.upcomingvisitparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.upcomingvisit();
      }else if(this.upcomingfollowupparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.upcomingfollowup();
      }else if(this.overdueparam == '1'){
        this.fromdate = "";
        this.todate = "";
        this.activitypage = true;
        this.allvisittracks = false;
        this.retailmovelink = false;
        if(this.roleid == '1'){
          this.filterview = true;
        }else{
          this.filterview = false;
        }
        this.batch1trigger();
        this.overduesection();
      }else if(this.allvisitparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.allvisits();
      }else if(this.inprogressparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.inprogresstrigger();
      }else if(this.interestedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.interestedtrigger();
      }else if(this.junkparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        if(this.roleid == '1'){
          if(this.notinterested == '0'){
            this.retailmovelink = false;
          }else{
            this.retailmovelink = true;
          }
        }else{
          this.retailmovelink = false;
        }
        this.junkclick();
      }else if(this.bookedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.bookedclick();
      }else if(this.bookingreqparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.bookingreqclick();
      }else if(this.rejectreqparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.rejectreqclick();
      }else if(this.retailmovedparam == '1'){
        this.batch2trigger();
        this.activitypage = false;
        this.allvisittracks = true;
        this.filterview = true;
        this.retailmovelink = false;
        this.retailmovedclick();
      }
  }

  batch1trigger(){
    // Todays Scheduled Counts Fetch
    var todayscheduled = {
      datefrom: 'scheduledtoday',
      dateto: 'scheduledtoday',
      statuss: '',
      stage: this.stagevalue,
    stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayscheduled).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.todayscheduled = compleads['RMLeads'].length;
      }else{
        this.todayscheduled = "0";
      }
      
      
    });
    // Todays Scheduled Counts Fetch

    // Todays Visited Counts Fetch
    var curmonth = this.currentDate.getMonth()+1;
    var curmonthwithzero = curmonth.toString().padStart(2, "0");
    var curday = this.currentDate.getDate();
    var curdaywithzero = curday.toString().padStart(2, "0");
    this.todaysdate = this.currentDate.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;

    var todayparam = {
      datefrom: this.todaysdate,
      dateto: this.todaysdate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(todayparam).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.todaystotalcounts = compleads['RMLeads'].length;
      }else{
        this.todaystotalcounts = "0";
      }
    });
     // Todays Visited Counts Fetch

    
    // Upcoming Visits Counts Fetch
    var upcomingvisit = {
      datefrom: 'upcomingvisit',
      dateto: 'upcomingvisit',
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(upcomingvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.upcomingvisits = compleads['RMLeads'].length;
      }else{
        this.upcomingvisits = "0";
      }
    });
    // Upcoming Visits Counts Fetch

    // Upcoming followups Counts Fetch
    var upcomingfollowup = {
      datefrom: 'upcomingfollowup',
      dateto: 'upcomingfollowup',
      statuss: '',
      stage: this.stagevalue,
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(upcomingfollowup).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.upcomingfollowups = compleads['RMLeads'].length;
      }else{
        this.upcomingfollowups = "0";
      }
    });
    // Upcoming followups Counts Fetch
    
    // Overdue Counts Fetch
    var visitoverdue = {
      datefrom: 'overdue',
      dateto: 'overdue',
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(visitoverdue).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.overdue = compleads['RMLeads'].length;
      }else{
        this.overdue = "0";
      }
    });
    // Overdue Counts Fetch
  }

  batch2trigger(){
    // if($('#fromdate').val() == undefined){
    //   this.fromdate = "";
    // }else if($('#fromdate').val() == ""){
    //   this.fromdate = "";
    // }else{
    //   this.fromdate = $('#fromdate').val();
    // }
    // if($('#todate').val() == undefined){
    //   this.todate = "";
    // }else if($('#todate').val() == ""){
    //   this.todate = "";
    // }else{
    //   this.todate = $('#todate').val();
    // }
    // All Visits Counts Fetch
    var paramcount = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(paramcount).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.totalcounts = compleads['RMLeads'].length;
        this.allcountsloadmore = compleads['RMLeads'].length;
      }else{
        this.totalcounts = "0";
        this.allcountsloadmore = compleads['RMLeads'].length;
      }
    });
    // All Visits Counts Fetch

    // inprogress Counts Fetch
    var inprogressvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Inprogress',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(inprogressvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.inprogress = compleads['RMLeads'].length;
      }else{
        this.inprogress = "0";
      }
    });
    // inprogress Counts Fetch

    // intrested Counts Fetch
    var intrestedvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Intrested',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(intrestedvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.interested = compleads['RMLeads'].length;
      }else{
        this.interested = "0";
      }
    });
    // intrested Counts Fetch

    // Junk Counts Fetch
    var junkvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'junk',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(junkvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.notinterested = compleads['RMLeads'].length;
      }else{
        this.notinterested = "0";
      }
    });
    // Junk Counts Fetch

     // booked Counts Fetch
     var bookedvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Booked',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(bookedvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.booked = compleads['RMLeads'].length;
      }else{
        this.booked = "0";
      }
    });
    // booked Counts Fetch

     // bookingrequests Counts Fetch
     var bookedvisit = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Bookingrequest',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(bookedvisit).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.bookingrequest = compleads['RMLeads'].length;
      }else{
        this.bookingrequest = "0";
      }
    });
    // bookingrequests Counts Fetch

    // rejectrequests Counts Fetch
    var rejectbooked = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'Rejected',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(rejectbooked).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.rejected = compleads['RMLeads'].length;
      }else{
        this.rejected = "0";
      }
    });
    // rejectrequests Counts Fetch

    // retailmoved Counts Fetch
    var retailmoved = {
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: 'retail',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }

    this._phpService.completeassignedRMLeads(retailmoved).subscribe(compleads => { 
      if(compleads['status'] == 'True'){
        this.retailmoved = compleads['RMLeads'].length;
      }else{
        this.retailmoved = "0";
      }
    });
    // retailmoved Counts Fetch
  }

  todayschedule(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    $(".other_section").removeClass("active");
    $(".schedule_today").addClass("active");
    this.fromdate = 'scheduledtoday';
    this.todate = 'scheduledtoday';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });

  }

  fetchtodaydata(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    $(".other_section").removeClass("active");
    $(".today_section").addClass("active");
    var curmonth = this.currentDate.getMonth()+1;
    var curmonthwithzero = curmonth.toString().padStart(2, "0");
    var curday = this.currentDate.getDate();
    var curdaywithzero = curday.toString().padStart(2, "0");
    this.todaysdate = this.currentDate.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;
    this.fromdate = this.todaysdate;
    this.todate = this.todaysdate;
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.todaysdate,
      dateto: this.todaysdate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      executid: this.rmid,
      propid:this.propertyid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        this.allcountsloadmore = compleads['RMLeads'].length;
      
    });
  }

  upcomingvisit(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    $(".other_section").removeClass("active");
    $(".upcoming_visit").addClass("active");
    this.fromdate = 'upcomingvisit';
    this.todate = 'upcomingvisit';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        this.allcountsloadmore = compleads['RMLeads'].length;
    });
  }

  upcomingfollowup()
  {
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    $(".other_section").removeClass("active");
    $(".upcoming_followup").addClass("active");
    this.fromdate = 'upcomingfollowup';
    this.todate = 'upcomingfollowup';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        this.allcountsloadmore = compleads['RMLeads'].length;
    });
  }

  overduesection(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    $(".other_section").removeClass("active");
    $(".overdues").addClass("active");
    this.fromdate = 'overdue';
    this.todate = 'overdue';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: '',
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
      this.filterLoader = false;
      this.callerleads = compleads['RMLeads']; 
      this.allcountsloadmore = compleads['RMLeads'].length;
    });
  }

  allvisits(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    this.actionid = '';
    // if($('#fromdate').val() == undefined){
    //   this.fromdate = "";
    // }else if($('#fromdate').val() == ""){
    //   this.fromdate = "";
    // }else{
    //   this.fromdate = $('#fromdate').val();
    // }
    // if($('#todate').val() == undefined){
    //   this.todate = "";
    // }else if($('#todate').val() == ""){
    //   this.todate = "";
    // }else{
    //   this.todate = $('#todate').val();
    // }
    $(".other_section").removeClass("active");
    $(".allvisit_section").addClass("active");
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      executid: this.rmid,
      propid:this.propertyid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        this.allcountsloadmore = compleads['RMLeads'].length;
      
    });
  }

  inprogresstrigger(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".progressclass").addClass("active");
    this.actionid = 'Inprogress';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  interestedtrigger()
  {
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".interestclass").addClass("active");
    this.actionid = 'Intrested';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  junkclick(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".junkclass").addClass("active");
    this.actionid = 'junk';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  bookedclick(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".bookedclass").addClass("active");
    this.actionid = 'Booked';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,
      
    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  bookingreqclick(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".bookrequestclass").addClass("active");
    this.actionid = 'Bookingrequest';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,

    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  rejectreqclick(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".rejectrequestclass").addClass("active");
    this.actionid = 'Rejected';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
      stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,

    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  retailmovedclick(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = false;
    // this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
    $(".other_section").removeClass("active");
    $(".retailmoveclass").addClass("active");
    this.actionid = 'retail';
    var param = {
      limit: 0,
      limitrows: 30,
      datefrom: this.fromdate,
      dateto: this.todate,
      statuss: this.actionid,
      stage: this.stagevalue,
stagestatus: this.stagestatusval,
      propid:this.propertyid,
      executid: this.rmid,
      loginuser:this.userid,
      team: this.team,

    }
    this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
        this.filterLoader = false;
        this.callerleads = compleads['RMLeads']; 
        // this.allcountsloadmore = compleads.length;
    });
  }

  // Updated Using Strategy


  //  ENQUIRY-VIEW-FROM-DB
  filteredexecutives: any;

  // activitydate() {
  //   LeadassignComponent.closedcount = 0;
  //   LeadassignComponent.count = 0;
  //   this.filterLoader = true;
  //   this.filterdata = true;
  //   this.datefilterview = true;
  //   this.fromdate = $('#fromdate').val();
  //   this.todate = $('#todate').val();
  //   this.actionid = "";

  //   this.batch2trigger();
  //   if(this.allvisitparam == '1'){
  //     this.allvisits();
  //   }else if(this.inprogressparam == '1'){
  //     this.inprogresstrigger();
  //   }else if(this.interestedparam == '1'){
  //     this.interestedtrigger();
  //   }else if(this.junkparam == '1'){
  //     this.junkclick();
  //   }else if(this.bookedparam == '1'){
  //     this.bookedclick();
  //   }else if(this.bookingreqparam == '1'){
  //     this.bookingreqclick();
  //   }
  // }

  filterhere() {
    this.fromdate = $('#fromdate').val();
    this.todate = $('#todate').val();
    if(this.fromdate == "" || this.todate == ""){
      this.router.navigate([], {
        queryParams: {
          from: "",
          to: ""
        },
        queryParamsHandling: 'merge',
      });
    }else if(this.fromdate == undefined || this.todate == undefined){
      this.router.navigate([], {
        queryParams: {
          from: "",
          to: ""
        },
        queryParamsHandling: 'merge',
      });
    }
    else{
      LeadassignComponent.closedcount = 0;
      LeadassignComponent.count = 0;
      // this.filterLoader = true;
      this.filterdata = true;
      this.datefilterview = true;
      this.actionid = "";
      // this.batch2trigger();
      this.router.navigate([], {
        queryParams: {
          from: this.fromdate,
          to: this.todate
        },
        queryParamsHandling: 'merge',
      });
      // if(this.allvisitparam == '1'){
      //   this.allvisits();
      // }else if(this.inprogressparam == '1'){
      //   this.inprogresstrigger();
      // }else if(this.interestedparam == '1'){
      //   this.interestedtrigger();
      // }else if(this.junkparam == '1'){
      //   this.junkclick();
      // }else if(this.bookedparam == '1'){
      //   this.bookedclick();
      // }else if(this.bookingreqparam == '1'){
      //   this.bookingreqclick();
      // }else if(this.rejectreqparam == '1'){
      //   this.rejectreqclick();
      // }
    }
    $('.modalclick').removeClass('modal-backdrop');
    $('.modalclick').removeClass('fade');
    $('.modalclick').removeClass('show');
    document.getElementsByClassName('more_filter_maindiv')[0].setAttribute("hidden", '');
    
  }

  dateclose(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.datefilterview = false;
    $('#fromdate').val("");
    $('#todate').val("");
    this.fromdate = "";
    this.todate = "";
    this.actionid = "";
    // this.batch2trigger();
    this.router.navigate([], {
      queryParams: {
        from: "",
        to: ""
      },
      queryParamsHandling: 'merge',
    });
    // if(this.allvisitparam == '1'){
    //   this.allvisits();
    // }else if(this.inprogressparam == '1'){
    //   this.inprogresstrigger();
    // }else if(this.interestedparam == '1'){
    //   this.interestedtrigger();
    // }else if(this.junkparam == '1'){
    //   this.junkclick();
    // }else if(this.bookedparam == '1'){
    //   this.bookedclick();
    // }else if(this.bookingreqparam == '1'){
    //   this.bookingreqclick();
    // }else if(this.rejectreqparam == '1'){
    //   this.rejectreqclick();
    // }
  }

  rmchange(vals) {
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.executivefilterview = true;
    // $('#filtermaindiv').removeClass('absol');
    if(vals.target.value == 'all'){
      if(this.roleid == '1'){
        this.execid = "";
        this.execname = "";
      }else{
        this.execid = "All";
        this.rmid = "All";
      }
      this.executivefilterview = false;
      // this.apitrigger();
      this.router.navigate([], {
        queryParams: {
          execid: this.execid,
          execname: ""
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.execid = vals.target.value;
      this.rmid = this,this.execid;
      this.execname = vals.target.options[vals.target.options.selectedIndex].text;
      // this.apitrigger();
      this.router.navigate([], {
        queryParams: {
          execid: this.execid,
          execname: this.execname
        },
        queryParamsHandling: 'merge',
      });
    }
    
  }

  propchange(vals){
    var element = document.getElementById('filtermaindiv');
    // alert(element.hasChildNodes());
    this.filterdata = true;
    this.propertyfilterview = true;
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.junk = true;
    
    if(vals.target.value == 'all'){
      this.propertyfilterview = false;
      this.propertyid = "";
      this.propertyname = "";
      this.router.navigate([], {
        queryParams: {
          property: "",
          propname: ""
        },
        queryParamsHandling: 'merge',
      });
      // this.apitrigger();
    }else{
      this.propertyid = vals.target.value;
      this.propertyname = vals.target.options[vals.target.options.selectedIndex].text;
      this.router.navigate([], {
        queryParams: {
          property: this.propertyid,
          propname: vals.target.options[vals.target.options.selectedIndex].text
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  statuschange(vals){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.stagefilterview = true;
    this.stagestatusval = "";
    this.stagestatusvaltext = "";
    this.stagevalue = vals.target.value;
    
    // this.apitrigger();
    if(this.stagevalue == "USV"){
      this.stagestatus = false;
      this.router.navigate([], {
        queryParams: {
          stage: vals.target.value,
          stagestatus: ""
        },
        queryParamsHandling: 'merge',
      });
    }else{
      this.stagestatus = true;
      this.router.navigate([], {
        queryParams: {
          stage: vals.target.value,
        },
        queryParamsHandling: 'merge',
      });
      $("#option-4").prop( "checked", false );
      $("#option-5").prop( "checked", false );
      $("#option-6").prop( "checked", false );
    }
  }

  stagestatuschange(vals){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.filterLoader = true;
    this.filterdata = true;
    this.stagestatusfilterview = true;
    this.stagestatusval = vals.target.value;
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        stagestatus: vals.target.value,
      },
      queryParamsHandling: 'merge',
    });
    if(this.stagestatusval == '1'){
      this.stagestatusvaltext = "Fixed";
    }else if(this.stagestatusval == '2'){
      this.stagestatusvaltext = "Refixed";
    }else if(this.stagestatusval == '3'){
      this.stagestatusvaltext = "Done";
    }
  }

  executiveclose(){
    
    $('#rm_dropdown').dropdown('clear');
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.executivefilterview = false;
    this.execid = "";
    this.execname = "";
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        execid: "",
        execname: ""
      },
      queryParamsHandling: 'merge',
    });

  }

  propertyclose()
  {
    $('#project_dropdown').dropdown('clear');
    this.propertyfilterview = false;
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.propertyid = "";
    this.stagestatusval = "";
    // this.apitrigger();
    this.router.navigate([], {
      queryParams: {
        property: "",
        propname: ""
      },
      queryParamsHandling: 'merge',
    });
  }

  teamclose(){
    this.teamfilterview = false;
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    this.team = "";
    this.router.navigate([], {
      queryParams: {
        team: "",
      },
      queryParamsHandling: 'merge',
    });
  }

  stageclose(){
    LeadassignComponent.closedcount = 0;
    LeadassignComponent.count = 0;
    $("#option-1").prop( "checked", false );
    $("#option-2").prop( "checked", false );
    $("#option-3").prop( "checked", false );
    $("#option-4").prop( "checked", false );
    $("#option-5").prop( "checked", false );
    $("#option-6").prop( "checked", false );
    this.stagefilterview = false;
    this.stagestatus = false;
    this.stagevalue = "";
    this.stagestatusval = "";
    this.router.navigate([], {
      queryParams: {
        stage: "",
        stagestatus:""
      },
      queryParamsHandling: 'merge',
    });
    // this.apitrigger();
  }

  movetoretail(){
    swal({
      title: 'Do you want to Move & Copy the '+ this.notinterested + ' leads?',
      showCancelButton: true,
      confirmButtonText: 'Move & Copy',
      cancelButtonText: `Cancel`,
      }).then((result) => {
        if (result.value) {
          this._phpService.movetoretail().subscribe(leadmoved => {
            if(leadmoved['status'] == 'True'){
              var bookedvisit = {
                limit: 0,
                limitrows: 10000000,
                datefrom: this.fromdate,
                dateto: this.todate,
                statuss: 'retail',
                stage: this.stagevalue,
                stagestatus: this.stagestatusval,
                propid:this.propertyid,
                executid: this.rmid,
                loginuser:this.userid,
                team: this.team,

              }
              this._phpService.completeassignedRMLeads(bookedvisit).subscribe(compleads => { 
                if(compleads['status'] == 'True'){
                  this.retailmoved = compleads['RMLeads'].length;
                  var movedleads = compleads['RMLeads'];
                  var arr2=[];
                  for(var i=0; i < this.retailmoved; i++){
                    arr2.push(movedleads[i].CustomerName +"-"+ movedleads[i].number);
                  }
                  var arraytoline = arr2.join('\n');
                  // var cloning =  structuredClone(arraytoline);
                  navigator.clipboard.writeText(arraytoline);
                  
                  this.router.navigateByUrl('/rmleadassign?retailmoved=1&from=&to=');
                }else{
                  this.retailmoved = "0";
                }
              });
              swal('Moved & Copied Successfully!', '', 'success');
            }else{
              swal('Leads Not Moved', '', 'error');
            }
          });
        } else {
          swal('Leads Moving Cancelled', '', 'info');
        }
      })
  }

  loadMoreassignedleads() {
    const limit = LeadassignComponent.count+=30;
      
    if(this.todaysscheduledparam == '1'){
      this.fromdate = 'scheduledtoday';
      this.todate = 'scheduledtoday';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.todayscheduled){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
          
        })
      }
    }else if(this.todaysvisitedparam == '1'){
      var curmonth = this.currentDate.getMonth()+1;
      var curmonthwithzero = curmonth.toString().padStart(2, "0");
      var curday = this.currentDate.getDate();
      var curdaywithzero = curday.toString().padStart(2, "0");
      this.todaysdate = this.currentDate.getFullYear()  + "-" + curmonthwithzero + "-" + curdaywithzero;
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.todaysdate,
        dateto: this.todaysdate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.todaystotalcounts){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.upcomingvisitparam == '1'){
      this.fromdate = 'upcomingvisit';
      this.todate = 'upcomingvisit';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.upcomingvisits){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.upcomingfollowupparam == '1'){
      this.fromdate = 'upcomingfollowup';
      this.todate = 'upcomingfollowup';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.upcomingfollowups){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.overdueparam == '1'){
      this.fromdate = 'overdue';
      this.todate = 'overdue';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.overdue){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.allvisitparam == '1'){
    //   this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.totalcounts){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.inprogressparam == '1'){
      // this.fromdate = $('#fromdate').val();
      // this.todate = $('#todate').val();
      this.actionid = 'Inprogress';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        propid:this.propertyid,
        executid: this.rmid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.interested){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.interestedparam == '1'){
      // this.fromdate = $('#fromdate').val();
      // this.todate = $('#todate').val();
      this.actionid = 'Intrested';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        propid:this.propertyid,
        executid: this.rmid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.interested){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.junkparam == '1'){
    //   this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
      this.actionid = 'junk';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.notinterested){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.bookedparam == '1'){
    //   this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
      this.actionid = 'Booked';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.booked){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.bookingreqparam == '1'){
    //   this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
      this.actionid = 'Bookingrequest';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.bookingrequest){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.rejectreqparam == '1'){
    //   this.fromdate = $('#fromdate').val();
    // this.todate = $('#todate').val();
      this.actionid = 'Rejected';
      var param = {
        limit: limit,
        limitrows: 30,
        datefrom: this.fromdate,
        dateto: this.todate,
        statuss: this.actionid,
        stage: this.stagevalue,
        stagestatus: this.stagestatusval,
        executid: this.rmid,
        propid:this.propertyid,
        loginuser:this.userid,
        team: this.team,

      }
      if(this.callerleads.length <= this.rejected){
        this.filterLoader = true;
        return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
          if(compleads['status'] == 'True'){
            this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
          this.filterLoader = false;
          }else{
            this.filterLoader = false;
          }
        })
      }
    }else if(this.retailmovedparam == '1'){
      // this.fromdate = $('#fromdate').val();
      // this.todate = $('#todate').val();
        this.actionid = 'retail';
        var param = {
          limit: limit,
          limitrows: 30,
          datefrom: this.fromdate,
          dateto: this.todate,
          statuss: this.actionid,
          stage: this.stagevalue,
  stagestatus: this.stagestatusval,
          executid: this.rmid,
          propid:this.propertyid,
          loginuser:this.userid,
          team: this.team,
        }
        if(this.callerleads.length <= this.retailmoved){
          this.filterLoader = true;
          return this._phpService.completeassignedRMLeads(param).subscribe(compleads => { 
            if(compleads['status'] == 'True'){
              this.callerleads = this.callerleads.concat(compleads['RMLeads']); 
            this.filterLoader = false;
            }else{
              this.filterLoader = false;
            }
          })
        }
    }
      

      
  }

  // TELECALLERS_LIST

  getcustomerexec() {
    this._phpService.customersupport().subscribe(callers => { this.telecallers = callers; })
    var executiveid = '50002'
    this._phpService.getexecutivesbasedid(executiveid).subscribe(execute => { this.executives = execute; })
  }

  // TELECALLERS_LIST


  getselectedleads(id){
    var checkid = $("input[name='programming']:checked").map(function(){
      return this.value;
    }).get().join(',');
    this.leadforwards.assignedleads = checkid;
    this.reassignleadforwards.assignedleads = checkid;
  }


  refresh() {
    this.filterLoader = true;
    this.filterdata = false;
    this.stagestatus = false;
    this.propertyid = "";
    if(localStorage.getItem('Role') == '50002'){
      this.rmid = localStorage.getItem('UserId'); 
    }else{
      this.rmid = "";
    }
    this.stagevalue = "";
    this.stagestatusval = "";
    $('#rm_dropdown').dropdown('clear');
    $('#project_dropdown').dropdown('clear');
    $("#option-1").prop( "checked", false );
    $("#option-2").prop( "checked", false );
    $("#option-3").prop( "checked", false );
    $("#option-4").prop( "checked", false );
    $("#option-5").prop( "checked", false );
    $("#option-6").prop( "checked", false );
    $(".other_section").removeClass("active");
    // $(".first_section").addClass("active");
    $(".schedule_today").addClass("active");
    this.apitrigger();
  }

  leaddetails = false;
  rmleaddetails = false;
  roleid:any;
  userid:any;
  leadtrack:any;
  historyfetch(id){
    this.leaddetails = true;
    this._phpService
  .getassignedrm(id,this.userid)
  .subscribe(cust => {
     this.selecteduser = cust[0];
  });
    
    var param2 = {
      leadid: id,
      roleid: this.roleid,
      userid: this.userid
    }
    this._phpService
    .gethistory(param2)
    .subscribe(history => {
      this.leadtrack = history['Leadhistory'];
    })
    $('.CSassigned_table').attr("style", "height: auto;"); 
    $('.remarksection').attr("style", "display: none;"); 
    $('.crm-auto-scroll').attr("style", "height: 650px;"); 
  }

  historyfetchrm(id){
    this.rmleaddetails = true;
    this._phpService
  .getassignedrm(id,this.userid)
  .subscribe(cust => {
     this.selecteduser = cust[0];
  });
    
    var param2 = {
      leadid: id,
      roleid: this.roleid,
      userid: this.userid
    }
    this._phpService
    .gethistory(param2)
    .subscribe(history => {
      this.leadtrack = history['Leadhistory'];
    })
    $('.CSassigned_table').attr("style", "height: auto;"); 
    $('.remarksection').attr("style", "display: none;"); 
    $('.crm-auto-scrollrm').attr("style", "height: 650px;"); 
  }
  closehistory(){
    // $('.panel-2').attr("style", "display: none;"); 
    this.rmleaddetails = false;
    this.leaddetails = false;
    $('.remarksection').removeAttr("style");
    $('.panel-1').removeAttr("style");
    $('.CSassigned_table').removeAttr("style");
  }

  morefilter()
    {
      document.getElementsByClassName('more_filter_maindiv')[0].removeAttribute("hidden");
      $('.modalclick').addClass('modal-backdrop');
      $('.modalclick').addClass('fade');
      $('.modalclick').addClass('show');
    }

}
